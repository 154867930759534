<template>
  <v-app>
    <v-content>
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card flat class="reset-block">
              <v-card-title>
                <v-img
                        t-data="hospital-logo"
                        :src="hospitalLogo"
                        alt=""
                        width="360"
                        max-width="100%"
                        max-height="60%"
                />
              </v-card-title>

              <div v-if="load" t-data="loading-content">
                <v-card-subtitle>
                  <p class="text">{{message}}</p>
                </v-card-subtitle>
              </div>
              <div v-if="!load" t-data="verified-content">
                <v-card-subtitle>
                  <p v-bind:class="[isSuccess ? 'text' : 'text_error']"><span v-html="message"></span></p>
                </v-card-subtitle>
              </div>
              <div v-if="!load" t-data="back-btn">
                <v-card>
                  <v-btn @click="submit"  color="primary"
                  block>
                    {{lang === 'th' ? 'กลับไปที่ ' + appName : 'Back to '+ appName}}
                  </v-btn
                  >
                </v-card>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import {mapActions} from "vuex";
  import Message from '../../constants/message'
  import {imageHospitalLogo, mobileAppName} from '@/constants/constants'

  const Email = Message.Email;

  export default {
    data() {
      return {
        message: this.$route.params.lang === 'en' ? Email.Verifying.en : Email.Verifying.th,
        load: false,
        isSuccess: false,
      }
    },
    mounted() {
      this.verifyToken();
    },
    computed: {
      appName() {
        return mobileAppName
      },
      hospitalLogo() {
        return imageHospitalLogo
      },
      lang: function () {
        return this.$route.params.lang ? this.$route.params.lang : 'th';
      },
    },
    methods: {
      submit() {
        //this.$router.push('https://18he6.app.link/StSKLdzcE2');
        window.location.href = process.env.VUE_APP_DEEP_LINK_URL
      },
      async verifyToken() {
        this.load = true;
        const payload = {
          token: this.$route.params.token
        }
        let success = await this.verify(payload)
        if (success) {
          this.isSuccess = true;
          this.message = this.$route.params.lang === 'en' ? Email.Successfully.en : Email.Successfully.th;
        } else {
          this.message = this.$route.params.lang === 'en' ? Email.Failed.en : Email.Failed.th;
        }
        this.load = false;
      },
      ...mapActions('verify', ['verify'])
    }
  }

</script>

<style lang="scss" scoped>
  .reset-block {
    padding: 30px 50px;
  }

  form {
    & > label {
      font-size: 15px;
    }
  }

  .logo {
    margin-left: 30px;
  }

  .text {
    font-size: 100%;
    padding-top: 20px;
    text-align: center;
    font-weight: bold;
    color: gray;
  }

  .text_error {
    font-size: 90%;
    padding-top: 20px;
    text-align: center;
    font-weight: bold;
    color: red;
  }

  .btn-confirm {
    width: 100%;
    font-size: 100%;
  }
</style>